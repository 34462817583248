import Css from "./style.module.scss";

import coloredLogo from "assets/coloredLogo.svg";

import { Badge } from "lib/common";
import { FiArrowLeft, FiUploadCloud } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { checkFetchingData } from "selectors/data";
import { getActiveOrganization, getProjectName } from "selectors/organizations";
import { getContactsData } from "selectors/contacts";
import {
  getGlobalStats,
  getSelectedBusinessData,
  getUserBusinessesData
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUploadingArchiveFilesCount } from "selectors/archive";
import { getUploadingDocumentsCount } from "selectors/documents";
import { getUserRestrictions, getUserRole } from "selectors/user";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import Pages from "lib/pages/Pages";
import React, { useLayoutEffect, useMemo } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";

const { LS_KEYS: { TASKS_VIEW_LAST_TAB } } = Constants;

const { MAIN, REPORTS, AUDIT, CONTACTS, DOCUMENTS, ARCHIVE, TASKS } = UiRoutes;

const Navbar = () => {
  const fetchingData = useSelector(checkFetchingData);

  const globalStatsData = useSelector(getGlobalStats);

  const userRole = useSelector(getUserRole);

  const userRestrictions = useSelector(getUserRestrictions);

  const { uiTexts } = useSelector(getTextsData);

  const { whiteLabel, name: organizationName, countryCode } = useSelector(getActiveOrganization);

  const projectName = useSelector(getProjectName);

  const documentsCurrentUploadCount = useSelector(getUploadingDocumentsCount);

  const archiveFilesCurrentUploadCount = useSelector(getUploadingArchiveFilesCount);

  const contactsData = useSelector(getContactsData);

  const [firstContact] = contactsData;

  const userBusinesses = useSelector(getUserBusinessesData);

  const singleBusiness = userBusinesses.length === 1;

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const { logoUrl = coloredLogo } = whiteLabel || {};

  const pagesList = useMemo(() => {
    return Object
      .values(Pages.getPageData([userRole, null, countryCode]))
      .filter(({ value }) => {
        switch (value) {
          case AUDIT:
          case MAIN:
            return false;
          case REPORTS:
            return !userRestrictions.reportsRead;
          case CONTACTS:
            return firstContact && !firstContact.short;
          default:
            return true;
        }
      });
  }, [
    userRole,
    countryCode,
    firstContact,
    userRestrictions
  ]);

  useLayoutEffect(() => {
    document.body.dataset.navbar = "";

    return () => {
      delete document.body.dataset.navbar;
    };
  }, []);

  return (
    <div className={Css.navBar}>
      <div className={Css.logo} title={organizationName}>
        <div className={Css.image}>
          <img src={logoUrl} title={projectName} />
        </div>
      </div>
      <div className={Css.navTabs}>
        {!singleBusiness && (
          <a href={MAIN} className={Css.navLink}>
            <span className={Css.inner}>
              <span className={Css.iconWrap}>
                <FiArrowLeft />
              </span>
              <span className={Css.title} title={uiTexts.toAllClients}>{uiTexts.toAllClients}</span>
            </span>
          </a>
        )}
        {pagesList.map((pageData) => {
          let {
            beta,
            value: route,
            disabled,
            titleLangId,
            urlParameters,
            statsNotificationTheme,
            statsNotificationProp,
            statsNotificationUrlParameters,
            iconComponent: Icon
          } = pageData;

          const title = uiTexts[titleLangId];

          const statsNotification = Utils.getPropertyByPath(globalStatsData, statsNotificationProp);

          if (statsNotification && statsNotificationUrlParameters) {
            urlParameters = { ...urlParameters, ...statsNotificationUrlParameters };
          }

          if (urlParameters) urlParameters = !!statsNotification && Utils.objectToQueryString(urlParameters);

          const pathData = {
            pathname: `/${selectedBusinessId}${route}${
              route === TASKS ? `/${Utils.storageValue(TASKS_VIEW_LAST_TAB) || "list"}` : ""
            }`,
            search: urlParameters ? `?${urlParameters}` : ""
          };

          const currentUploadCount = (route === DOCUMENTS && documentsCurrentUploadCount)
            || (route === ARCHIVE && archiveFilesCurrentUploadCount);

          const badge = (currentUploadCount || statsNotification) ? (
            <Badge
              data-theme={currentUploadCount ? "warning" : statsNotificationTheme}
              className={classNames(Css.notification, currentUploadCount && Css.upload)}
              value={currentUploadCount || statsNotification} />
          ) : null;

          return (
            <NavLink
              key={route}
              disabled={disabled || fetchingData}
              className={Css.navLink}
              to={pathData}>
              <span className={Css.inner}>
                <span className={Css.iconWrap}>
                  {currentUploadCount ? <FiUploadCloud /> : <Icon />}
                </span>
                <span className={Css.title} title={title}>{title}</span>
                {badge}
              </span>
              {beta && <span className={Css.betaBadge}>{uiTexts.beta}</span>}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Navbar;
